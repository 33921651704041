import Vue from 'vue'
import VueGtm from 'vue-gtm'
import { Store } from 'vuex'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { isServer, provisionType } from '@vue-storefront/core/helpers'
import { categoryResolver } from 'theme/helpers/categoryResolverFunction'
import { GATracker } from 'src/modules/vsf-beecommerce-ga-tracking/interfaces'
import { currency } from 'src/modules/vsf-beecommerce-ga-tracking/common'
import { config as trackingConfig } from 'src/modules/vsf-beecommerce-ga-tracking/tracking'

export const setGTMconfig = function (store) {
  if (Vue.prototype.$googleTagManager) {
    const currency = currentStoreView().i18n.currencyCode
    const language = currentStoreView().i18n.defaultLanguage

    trackingConfig({ currency, language })
  };
}

export const getNextProduct = ({
  item,
  list = undefined,
  list_id = undefined,
  position = undefined
}) => {
  if (item.type_id === 'bundle') {
    let minPrices = {}
    for (let i = 0; i < item.bundle_options.length; i++) {
      let minPrice = 0
      if (item.bundle_options[i].required) {
        item.bundle_options[i].product_links.forEach(link => {
          if (minPrice === 0 || link.price < minPrice) {
            minPrice = link.price
            minPrices[i] = link.price
          }
        })
      }
    }
    let current_price = Object.keys(minPrices).reduce((sum, key) => sum + parseFloat(minPrices[key] || 0), 0)
    item.ga_fields = { price: current_price }
  }
  return {
    ...item,
    list,
    list_id,
    position
  }
}

export const getCartItems = (cartItems, fullPath) => {
  return cartItems.map((item, index) =>
    getNextProduct({
      item,
      list: fullPath === '/checkout' ? 'Checkout' : 'Microcart',
      position: index + 1
    })
  )
}

export const isEnabled = (gtmId: string | null) => {
  return typeof gtmId === 'string' && gtmId.length > 0 && !isServer
}

export function afterRegistration (config, store: Store<any>) {
  const GTM: GATracker = Vue.prototype.$googleTagManager
  if (isEnabled(config.googleTagManager.id)) {
    const GTM: VueGtm = (Vue as any).gtm

    // TODO: try to change on native trackView with custom params
    GTM.trackView = function (screenName, path) {}

    const storeView = currentStoreView()
    const currencyCode = storeView.i18n.currencyCode

    const getProduct = (item) => {
      const breadcrumbs = store.getters['breadcrumbs/getBreadcrumbsRoutes']
      const menuCategories = store.getters['category-next/getMenuCategories']

      const categoryIds = breadcrumbs.map(cat => cat.id).join('~')

      const { attribute_set_id, author, bestseller, bundle_options, category, id, image, main_category, name, news_to_date, sku, stock, type_id, parentSku, regular_price, special_price_incl_tax, qty: quantity } = item

      let current_price = special_price_incl_tax !== 0 ? special_price_incl_tax : regular_price

      if (!current_price && type_id === 'bundle') {
        let minPrices = {}
        for (let i = 0; i < bundle_options.length; i++) {
          let minPrice = 0
          if (bundle_options[i].required) {
            bundle_options[i].product_links.forEach(link => {
              if (minPrice === 0 || link.price < minPrice) {
                minPrice = link.price
                minPrices[i] = link.price
              }
            })
          }
        }
        current_price = Object.keys(minPrices).reduce((sum, key) => sum + parseFloat(minPrices[key] || 0), 0)
      }

      current_price = Math.abs(parseFloat(current_price)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(',', '')
      let { deepestCategory, mainCategory, categoriesIdsFromResolver } = categoryResolver(category, main_category, store)
      // find category name by id
      let crmCategory
      menuCategories.forEach(category => {
        if (crmCategory) return
        if (category.id === main_category) {
          crmCategory = category.name
          return
        }
        if (category.children_data) {
          category.children_data.forEach(subCategory => {
            if (crmCategory) return
            if (subCategory.id === main_category) {
              crmCategory = subCategory.name
            }
          })
        }
      })

      const inStock = stock && typeof stock.is_in_stock === 'boolean'
        ? stock.is_in_stock
          ? 'Tak'
          : 'Nie'
        : 'Nie'
      const source = stock && stock.source_code

      const isOnSale = special_price_incl_tax ? 'Tak' : 'Nie'

      let isNew = 'Nie'
      let expireDate = news_to_date
        ? Date.parse(news_to_date)
        : null
      if (expireDate) {
        const currentDate = new Date()
        isNew = currentDate.getTime() < expireDate ? 'Tak' : 'Nie'
      }
      const isBestseller = bestseller ? 'Tak' : 'Nie'

      let product = {
        name: name,
        metric1: regular_price,
        dimension2: provisionType(deepestCategory),
        dimension10: source || '',
        dimension11: isBestseller,
        dimension12: isOnSale,
        dimension14: isNew,
        dimension15: type_id,
        dimension16: inStock,
        dimension17: '',
        dimension18: author || '',
        dimension19: mainCategory,
        dimension20: deepestCategory ? deepestCategory.join(',') : '',
        childSku: sku,
        fbId: id,
        id: parentSku,
        price: current_price,
        category: mainCategory,
        product_category_ids: categoriesIdsFromResolver,
        product_category_names: deepestCategory || [],
        product_images: config.api.imagesUrl + '/1000/1000/resize/product' + image,
        ecomm_totalvalue: current_price
      }
      if (quantity) {
        product['quantity'] = quantity
      }

      return product
    }

    const checkoutObj = (step, products, user?) => {
      let cartProducts = []
      let productIds = []
      let total = 0
      products.map(product => {
        productIds.push(product.id)
        total += product.price
      })
      for (let product of products) {
        cartProducts.push(getProduct(product))
      }
      return {
        event: 'checkout',
        eventCategory: 'Ecommerce',
        eventAction: 'checkout',
        ecomm_prodid: productIds,
        ecomm_pagetype: 'cart',
        ecomm_totalvalue: total,
        user_firstname: user && user.firstName,
        user_lastname: user && user.lastName,
        'user-email': user && user.emailAddress,
        user_consent: user && user.password && user.password.length > 3, // todo: is user create password?
        ecommerce: {
          checkout: {
            actionField: {
              step: step
            },
            products: cartProducts
          }
        }
      }
    }

    store.subscribe(({ type, payload }, state) => {
      const target = (window as any);
      let dataLayer = (target.dataLayer = target.dataLayer || [])
      // set type of page
      const setCurrentPage = () => {
        let isHomePage = payload.to.name === 'home'
        let isProductPage = !!payload.to.params.parentSku
        let isCheckoutPage = payload.to.name === 'checkout' || payload.to.name === 'en-checkout'
        let isStaticPage = payload.to.name === 'cms-page' || payload.to.name === 'en-cms-page'
        let isTYP = payload.to.name === 'thank-you-page' || payload.to.name === 'en-thank-you-page'
        let isSearchPage = payload.to.name && payload.to.name.includes('urldispatcher') && payload.to.query.hasOwnProperty('query') && !isProductPage
        let isCategoryPage = payload.to.name && payload.to.name.includes('urldispatcher') && !isProductPage
        let isPageNotFound = payload.to.name === 'page-not-found'
        let isAccountPage = payload.to.name.includes('my-')
        let isBrandPage = payload.to.params.slug === 'brands' || payload.to.params.slug === 'marki'
        if (isBrandPage) {
          return 'brand'
        } else if (isHomePage) {
          return 'homepage'
        } else if (isProductPage) {
          return 'product'
        } else if (isCheckoutPage) {
          return 'checkout'
        } else if (isStaticPage) {
          return 'static-page'
        } else if (isTYP) {
          return 'thank-you-page'
        } else if (isPageNotFound) {
          return 'page-not-found'
        } else if (isAccountPage) {
          return 'my-account'
        } else if (isSearchPage) {
          return 'search-page'
        } else if (isCategoryPage) {
          return 'category'
        } else {
          return 'other'
        }
      }

      const getPageTitle = () => {
        let currentCategory = store.getters['category-next/getCurrentCategory']
        let pageTitle = ''
        switch (setCurrentPage()) {
          case 'homepage':
            pageTitle = 'Strona główna'
            break
          case 'product':
            pageTitle = state.product.current.meta_title || state.product.current.name
            break
          case 'checkout':
            pageTitle = 'Zamówienie'
            break
          case 'static-page':
            pageTitle = state.cmsPage.current ? state.cmsPage.current.meta_title || state.cmsPage.current.title : ''
            break
          case 'page-not-found':
            pageTitle = '404 Nie ma takiej strony'
            break
          case 'category':
            pageTitle = Object.keys(currentCategory).length
              ? currentCategory.meta_title || currentCategory.name
              : ''
            break
          default:
            pageTitle = 'Prasa, książki, prenumerata'
            break
        }
        if (pageTitle.length < 45) return pageTitle.concat(' - Kultowy.pl')
        if (pageTitle.length > 60) return pageTitle.slice(0, 60)
        return pageTitle
      }

      // Changing route
      if (type === 'ui/routeChanged' || (type === 'route/ROUTE_CHANGED' && payload.to.name === 'checkout' && payload.to.hash)) {
        if (!currency) setGTMconfig(store)
        let orderHistorySumm = state.user.current && state.user.orders_history ? state.user.orders_history.items.length && state.user.orders_history.items.reduce((a, b) => a + b.grand_total, 0) : null
        GTM.trackEvent({
          event: 'route-changed',
          'content-title': getPageTitle(),
          'content-type': setCurrentPage(),
          'content-name': payload.to.path,
          'content-view-name': payload.to.name,
          'visitor-login-state': state.user.current ? 'logged-in' : 'logged-out',
          'cart-is-empty': !!state.cart.cartItems.length,
          'cart-is-virtual': state.cart.cartItems.length ? state.cart.cartItems.every(itm => itm.type_id === 'downloadable' || itm.type_id === 'virtual' || itm.is_virtual_bundle) : false,
          'user-id': state.user.current && state.user.current.id,
          'user-email': state.user.current && state.user.current.email,
          'newsletter-member': state.user.current && state.user.current.extension_attributes.is_subscribed,
          // 'subscribtion-member': TODO: subscription in order
          'transaction-history': state.user.orders_history && state.user.orders_history.items.length + '/' + orderHistorySumm,
          'from-url': setCurrentPage() === 'page-not-found' && payload.to.fullPath,
          language: 'pl', // TODO
          uiGender: null, // TODO
          uiCity: null, // TODO
          uiUser: null, // TODO
          transactionShippingMethod: null // TODO
        })
        GTM.trackEvent({
          event: 'content-view',
          'content-name': payload.to.path,
          'content-type': setCurrentPage()
        })
        // Single product page
        if (payload.to.params.parentSku) {
          const currentProduct = store.getters['product/getCurrentProduct']
          GTM.trackEvent({
            event: 'uaevent',
            eventCategory: 'Ecommerce',
            eventAction: 'Product Details',
            ecommerce: {
              detail: {
                actionField: {
                  list: 'product detail page'
                },
                products: [getProduct(currentProduct)]
              }
            }
          });
          Vue.prototype.$googleTagManager.detail({
            product: getNextProduct({
              item: currentProduct
            })
          })
        }

        if (payload.to.name.includes('checkout')) {
          // Start checkout
          let step
          if (!payload.to.hash) step = 2
          else if (payload.to.hash === '#shipping') step = 3
          else if (payload.to.hash === '#payment') step = 4
          else if (payload.to.hash === '#orderReview') step = 5

          if (step) {
            GTM.trackEvent(
              checkoutObj(step, state.cart.cartItems, state.checkout.personalDetails)
            );
            Vue.prototype.$googleTagManager.checkout({
              products: getCartItems(state.cart.cartItems, state.route.fullPath),
              step,
              stateCheckout: state.checkout
            })
          }
        }
      }

      // Adding a Product to a Shopping Cart
      if (type === 'cart/cart/ADD') {
        GTM.trackEvent({
          event: 'addToCart',
          eventCategory: 'Ecommerce',
          eventAction: 'Add to Cart',
          ecommerce: {
            currencyCode: currencyCode,
            add: {
              products: [getProduct(payload.product)]
            }
          }
        });
        Vue.prototype.$googleTagManager.addToCart({
          product: getNextProduct({
            item: payload.product,
            list: 'Product Page'
          })
        })
      }

      // Open cart
      if (type === 'ui/setMicrocart') {
        if (payload) {
          GTM.trackEvent(checkoutObj(1, state.cart.cartItems));
        }
        Vue.prototype.$googleTagManager.checkout({
          products: getCartItems(state.cart.cartItems, state.route.fullPath),
          step: 1
        })
      }

      // Removing a Product from a Shopping Cart
      if (type === 'cart/cart/DEL') {
        GTM.trackEvent({
          event: 'removeFromCart',
          eventCategory: 'Ecommerce',
          eventAction: 'Remove from Cart',
          ecommerce: {
            remove: {
              products: [getProduct(payload.product)]
            }
          }
        });
        Vue.prototype.$googleTagManager.removeFromCart({
          product: getNextProduct({
            item: payload.product,
            list: 'Microcart'
          })
        })
      }

      // Add product to wishlist
      if (type === 'wishlist/wishlist/ADD') {
        GTM.trackEvent({
          event: 'addToWhishlist',
          eventCategory: 'Engagement',
          eventAction: 'Add to whishlist',
          label: payload.product.name
        });
        Vue.prototype.$googleTagManager.wishlist({
          product: getNextProduct({
            item: payload.product
          })
        })
      }
      // Open Outbound Link
      if (type === 'ui/openOutboundLink') {
        GTM.trackEvent({
          event: 'clickOutboundLink',
          eventCategory: 'Outbound link',
          eventAction: payload.text,
          label: payload.link
        });
      }
      // Usercome push
      if (type === 'ui/usercomCheckoutPush') {
        GTM.trackEvent({
          event: 'usercomevent',
          eventCategory: 'user',
          eventAction: 'order',
          email: payload.email,
          firstName: payload.firstName,
          lastName: payload.lastName,
          region: payload.region,
          city: payload.city,
          extension_attributes: payload.extension_attributes,
          consent_to_ng_commercials: payload.consent_to_ng_commercials
        })
      }

      // Measuring Views of Product Details
      /*
      if (type === 'product/product/SET_CURRENT') {
        GTM.trackEvent({
          ecommerce: {
            event: 'uaevent',
            eventCategory: 'Ecommerce',
            eventAction: 'Product Details',
            detail: {
              'actionField': { 'product detail page': '' }, // 'detail' actions have an optional list property.
              'products': [getProduct(payload)]
            }
          }
        });
      }
      */

      // Measuring Views of Promotions
      if (type === 'ui/VIEW_BANNER') {
        if (payload) {
          GTM.trackEvent({
            event: 'uaevent',
            eventCategory: 'Promotion',
            eventAction: 'View',
            ecommerce: {
              promoView: {
                promotions: [
                  {
                    name: payload.name,
                    position: payload.position,
                    creative: payload.creative
                  }
                ]
              }
            }
          })
        }
      }
      if (type === 'ui/CLICK_BANNER') {
        if (payload) {
          GTM.trackEvent({
            event: 'uaevent',
            eventCategory: 'Promotion',
            eventAction: 'Click',
            ecommerce: {
              promoClick: {
                promotions: [
                  {
                    name: payload.name,
                    position: payload.position,
                    creative: payload.creative
                  }
                ]
              }
            }
          })
        }
      }

      // Added in theme templates:
      // ProductTile:
      // <router-link class="block no-underline product-link" :to="productLink" @click.native="productClick" data-testid="productLink">

      // ProductTileRelated

      // ProductListing:
      // <product-tile :product="product" :position="key" list="catalog"/>

      // Added in banner module:
      // Banner
      // BannerBlock
      // SmallBanner

      // Register trackEvent
      // components/core/blocks/Auth/Register.vue
    })
  }
}
