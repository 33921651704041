<template>
  <div class="payment-cash-on-delivery-info-container" :class="{ 'mb15' : !token_data.value }">
    <div v-if="!getTokenData" id="card-widget">
      <div class="cart-token cl-burnt-sienna">
        Aby kupić prenumeratę autoodnawialną użyj karty debetowej lub kredytowej
      </div>
      <section class="container">
        <div class="card-container">
          <aside>Numer karty</aside>
          <div class="payu-card-form" id="payu-card-number" />
          <div class="card-details clearfix">
            <div class="expiration">
              <aside>Data ważności</aside>
              <div class="payu-card-form" id="payu-card-date" />
            </div>
            <div class="cvv">
              <aside>CVV</aside>
              <div class="payu-card-form" id="payu-card-cvv" />
            </div>
          </div>
        </div>
        <button id="tokenizeButton" @click="tokenizeAction">
          Użyj i zapisz kartę
        </button>
        <div id="responseTokenize" class="cl-black">
          {{ payuResponse }}
        </div>
      </section>
    </div>
    <div v-else class="success-cart-token weight-500 mb20 mt10">
      Twoja karta została zapisana w bezpieczny sposób. Użyjemy jej automatycznie do odnowienia twojej prenumeraty. Kilka dni przed odnowieniem otrzymasz informację mailową o planowanym odnowieniu.
    </div>
  </div>
</template>

<script>
import { KEY } from './../index'
import * as types from './../store/mutation-types'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import config from 'config'
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  mixins: [validationMixin],
  name: 'PaymentPayURecurring',
  validations: {
    token_data: {
      required
    }
  },
  data () {
    return {
      payuSdkForms: window.PayU(config.payment_payu.cardWidget.merchantPosId),
      messages: [],
      optionsForms: {
        cardIcon: true,
        style: {
          basic: {
            fontSize: '24px'
          }
        },
        placeholder: {
          number: '',
          date: 'MM/RR',
          cvv: ''
        },
        lang: 'pl'
      }
    }
  },
  computed: {
    ...mapGetters({
      getTokenData: 'payment-payu-recurring/getTokenData'
    }),
    isValid () {
      return !this.$v.token_data.$invalid
    },
    changeToken () {
      return this.$emit('changeUpdate', this.isValid)
    },
    token_data: {
      get () {
        return this.$store.getters[KEY + '/' + 'getTokenData']
      },
      set (newValue) {
        this.$store.commit(KEY + '/' + types.SET_PAYU_METHOD, newValue)
        this.$store.commit(KEY + '/' + types.SET_TOKEN_DATA, newValue)
      }
    },
    payuResponse () {
      return this.messages.join(', ');
    }
  },
  methods: {
    renderError (errors) {
      errors.forEach((error) => {
        this.messages.push(error.message);
      });
    },
    renderSuccess (token) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message: 'Twoja karta została zapisana w bezpieczny sposób.',
        action1: { label: 'OK' }
      })
      this.token_data = token
    },
    attachPayUWidget () {
      let secureForms = this.payuSdkForms.secureForms();

      let cardNumber = secureForms.add('number', this.optionsForms);
      let cardDate = secureForms.add('date', this.optionsForms);
      let cardCvv = secureForms.add('cvv', this.optionsForms);

      try {
        cardNumber.render('#payu-card-number');
        cardDate.render('#payu-card-date');
        cardCvv.render('#payu-card-cvv');
      } catch (e) {
        console.log(e);
      }
    },
    tokenizeAction () {
      this.message = []
      try {
        this.payuSdkForms.tokenize(config.payment_payu.cardWidget.tokenType).then((result) => {
          result.status === 'SUCCESS'
            ? this.renderSuccess(result.body.token)
            : this.renderError(result.error.messages);
        });
      } catch (e) {
        console.log(e); // technical errors
      }
    }
  },
  mounted () {
    this.attachPayUWidget();
  },
  beforeDestroy () {
    window.payUWidgetCallback = null;
  }
}
</script>

<style lang="scss" scoped>
  .px20-xs {
    @media (max-width: 767px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .blik-text {
    flex-direction: column;
  }
  .banks__link img {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    height: auto;
    display: block;
    margin-bottom: 10px;
  }
  .blik__header {
    font-size: 30px;
    text-transform: none;
  }
  .blik__close {
    color: white;
    background-color: #da3800;
    border-radius: 20px;
    margin: 0;
    padding: 10px 0;
    font-size: 18px;
  }
  .mx40-sm {
    @media (min-width: 768px) {
      margin-left: 40px;
      margin-right: 40px;
    }
  }

  #card-widget {
    .container * {
      // font-family: Arial, Helvetica, sans-serif;
      font-size: 16px;
      // color: #ffffff;
    }

    .container {
      text-align: left;
      width: 420px;
      margin: 10px 0;
      padding: 0;
      display: block;
      border-radius: 5px;
      box-sizing: border-box;
    }

    .card-container {
      width: 100%;
      margin: 0 auto;
      border-radius: 6px;
      padding: 10px;
      text-align: left;
      box-sizing: border-box;
    }

    .card-container aside {
      padding-bottom: 6px;
      color: black;
    }

    .payu-card-form {
      background-color: #ffffff;
      padding: 5px;
      border-radius: 4px;
    }

    .card-details {
      clear: both;
      overflow: auto;
      margin-top: 10px;
    }

    .card-details .expiration {
      width: 50%;
      float: left;
      padding-right: 5%;
    }

    .card-details .cvv {
      width: 45%;
      float: left;
    }

    button {
      border-radius: 50px;
      border: none;
      background: #1396EC;
      color: #ffffff;
      padding: 8px 15px;
      margin: 10px auto;
      margin-left: 10px;
      cursor: pointer;
    }

    .response-success {
      color: #438F29;
    }

    .response-error {
      color: #FF4600;
    }
  }
</style>
