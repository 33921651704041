import config from 'config'
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers'
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import { Logger } from '@vue-storefront/core/lib/logger'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export const subscriptionsStore = {
  namespaced: true,
  state: {
    userOrders: [],
    customerSubscriptionProducts: [],
    customerActiveSubscriptionProducts: []
  },
  getters: {
    getUserOrders: (state) => state.userOrders,
    getCustomerSubscriptionProducts: (state) => state.customerSubscriptionProducts,
    getCustomerActiveSubscriptionProducts: (state) => state.customerActiveSubscriptionProducts
  },
  mutations: {
    setUserOrders (state, payload) {
      state.userOrders = payload
    },
    setCustomerSubscriptionProducts  (state, payload) {
      state.customerSubscriptionProducts = payload
    },
    setActiveCustomerSubscriptionProducts  (state, payload) {
      state.customerActiveSubscriptionProducts = payload
    }
  },
  actions: {
    getUserOrders ({ commit }, { currentToken }): Promise<Response> {
      if (currentToken !== null) {
        return new Promise((resolve, reject) => {
          const url = processLocalizedURLAddress(getApiEndpointUrl(config.cart, 'orders_endpoint').replace('{{token}}', currentToken))
          TaskQueue.execute({
            url,
            payload: {
              method: 'GET',
              headers: {
                'Accept': 'application/json'
              },
              mode: 'cors'
            }
          }).then(resp => {
            if (resp.resultCode === 200) {
              Logger.info('Orders loaded')()
              if (resp.result) {
                if (resp.result.errors) reject(resp.result.errors.message)
                return resp.result[0].orders.order
                  ? resolve(resp.result[0].orders.order)
                  : reject('customerSubscriptionProducts is empty')
              }
            } else {
              reject(resp)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    getUserSubscriptionItems ({ commit }, { currentToken }): Promise<Response> {
      if (currentToken !== null) {
        return new Promise((resolve, reject) => {
          const url = processLocalizedURLAddress(getApiEndpointUrl(config.cart, 'recurrence_endpoint').replace('{{token}}', currentToken))
          TaskQueue.execute({
            url,
            payload: {
              method: 'GET',
              headers: {
                'Accept': 'application/json'
              },
              mode: 'cors'
            }
          }).then(resp => {
            if (resp.resultCode === 200) {
              Logger.info('Subscription links loaded')()
              if (resp.result) {
                if (resp.result.errors) reject(resp.result.errors.message)
                return resp.result.items
                  ? resolve(resp.result.items)
                  : reject('customerSubscriptionProducts is empty')
              }
            } else {
              reject(resp)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    cancelUserSubscriptionItem ({ commit }, { currentToken, id }): Promise<Response> {
      if (currentToken !== null || id) {
        return new Promise((resolve, reject) => {
          const url = processLocalizedURLAddress(getApiEndpointUrl(config.cart, 'recurrence_cancel_endpoint').replace('{{token}}', currentToken))
          TaskQueue.execute({
            url,
            payload: {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
              },
              mode: 'cors',
              body: JSON.stringify({
                'recurrence': { 'id': id }
              })
            }
          }).then(resp => {
            if (resp.code === 200) {
              Logger.info('Subscription canceled')()
              if (resp.result) {
                resolve(resp.result)
              }
              reject('customerSubscriptionProducts is empty')
            } else {
              reject(resp)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    getUserAciveSubscriptions ({ commit }, { currentToken }): Promise<Response> {
      if (currentToken !== null) {
        return new Promise((resolve, reject) => {
          const url = processLocalizedURLAddress(getApiEndpointUrl(config.cart, 'active_subscriptions').replace('{{token}}', currentToken).replace('{{storeCode}}', currentStoreView().storeCode))
          TaskQueue.execute({
            url,
            payload: {
              method: 'GET',
              headers: {
                'Accept': 'application/json'
              },
              mode: 'cors'
            }
          }).then(resp => {
            if (resp.resultCode === 200) {
              Logger.info('Subscription links loaded')()
              if (resp.result) {
                if (resp.result.errors) reject(resp.result.errors.message)
                return resp.result[0]
                  ? resolve(resp.result[0].subscriptions)
                  : reject('customerSubscriptionProducts is empty')
              }
            } else {
              reject(resp)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    }
  }
}
