import Vue from 'vue'
import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import SubscryptionProductState from '../types/SubscriptionProductState'

const mutations: MutationTree<SubscryptionProductState> = {
  [types.LOAD_SUBSCRIPTION_DETAILS] (state, { sku, response }) {
    Vue.set(state.numberOptions, sku, response)
  },
  [types.RESET_SUBSCRIPTION_DETAILS] (state) {
    state.numberOptions = {}
  }
}

export default mutations
