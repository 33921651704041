import { GetterTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import SubscriptionProductState from '../types/SubscriptionProductState'

const getters: GetterTree<SubscriptionProductState, RootState> = {
  getProductNumberOptions: state => sku => {
    return state.numberOptions[sku]
  }
}

export default getters
