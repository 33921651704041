import { Module } from 'vuex'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import state from './state'
import RootState from '@vue-storefront/core/types/RootState'
import SubscryptionProductState from '../types/SubscriptionProductState'

export const subscriptionProduct: Module<SubscryptionProductState, RootState> = {
  namespaced: true,
  actions,
  mutations,
  getters,
  state
}
