import Product from '@vue-storefront/core/modules/catalog/types/Product';
import { isSpecialPriceActive } from '@vue-storefront/core/modules/catalog/helpers/taxCalc'
interface BaseProductLink {
  product?: Product,
  qty?: number,
  price?: number
}

export const calculateProductLinkPrice = ({ price = 1, priceInclTax = 1, qty = 1 }) => {
  const product = {
    price: 0,
    priceInclTax: 0
  }
  const qtyNum = typeof qty === 'string' ? parseInt(qty) : qty
  if (qtyNum >= 0) {
    product.price += price * qtyNum
    product.priceInclTax += priceInclTax * qtyNum
  }
  return product
}

export const getProductLinkPrice = (productLinks: BaseProductLink[], priceType?: number) => productLinks
  .map((productLink) => {
    let product
    if (productLink.price && priceType !== 0) {
      product = { price: productLink.price, price_incl_tax: productLink.price, priceInclTax: productLink.price }
    } else if (productLink.product) {
      if (productLink.product.special_price && (productLink.product.special_price < productLink.product.regular_price)) {
        if (isSpecialPriceActive(productLink.product.special_from_date, productLink.product.special_to_date)) {
          product = { price: productLink.product.special_price, price_incl_tax: productLink.product.special_price, priceInclTax: productLink.product.special_price }
        } else {
          product = { price: productLink.product.price_incl_tax, price_incl_tax: productLink.product.price_incl_tax, priceInclTax: productLink.product.price_incl_tax }
        }
      } else {
        product = { price: productLink.product.price_incl_tax, price_incl_tax: productLink.product.price_incl_tax, priceInclTax: productLink.product.price_incl_tax }
      }
    } else {
      product = { price: 0, price_incl_tax: 0, priceInclTax: 0 }
    }
    return calculateProductLinkPrice({
      price: product.price,
      priceInclTax: product.price_incl_tax || product.priceInclTax,
      qty: productLink.qty
    })
  })
  .reduce(
    (priceDelta, currentPriceDelta) => ({
      price: currentPriceDelta.price + priceDelta.price,
      priceInclTax: currentPriceDelta.priceInclTax + priceDelta.priceInclTax
    }),
    { price: 0, priceInclTax: 0 }
  )
