import * as types from './store/mutation-types'
import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { isServer } from '@vue-storefront/core/helpers'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { getGuaClientId } from 'theme/utils/getGuaClientId'
import { getGuaUa } from 'theme/utils/getGuaUa'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import i18n from '@vue-storefront/i18n'
import { Logger } from '@vue-storefront/core/lib/logger'
import PayUWidgetComponent from './components/PayUWidgetV2.vue'
import Vue from 'vue';

import config from 'config'

const PaymentPayURecurringStore = {
  namespaced: true,
  state: {
    methods: null,
    additional_payment_data: {
      payu_method: '',
      payu_method_type: 'CARD_TOKEN',
      gua_client_id: '',
      parcel_locker_no: '',
      recurring: 'FIRST'
    },
    token_data: ''
  },
  mutations: {
    [types.SET_BACKEND_PAYMENT_METHODS] (state, paymentMethods) {
      state.methods = paymentMethods
    },
    [types.SET_GUA_CLIENT_ID] (state, gua_client_id) {
      state.additional_payment_data.gua_client_id = gua_client_id
    },
    [types.SET_GUA_UA] (state, gua_user_agent) {
      if (gua_user_agent) { state.additional_payment_data.gua_ua = gua_user_agent }
    },
    [types.SET_PAYU_METHOD] (state, payu_method) {
      if (typeof payu_method !== 'undefined') { state.additional_payment_data.payu_method = payu_method }
    },
    [types.SET_PARCEL_LOCKER_NO] (state, parcel_locker_no) {
      state.additional_payment_data.parcel_locker_no = parcel_locker_no
    },
    [types.SET_TOKEN_DATA] (state, token_data) {
      state.token_data = token_data
    }
  },
  getters: {
    getAdditionalPaymentData: state => state.additional_payment_data,
    getTokenData: state => state.token_data
  }
}

export const KEY = 'payment-payu-recurring'

export const PaymentPayURecurringModule: StorefrontModule = function ({ store }) {
  store.registerModule('payment-payu-recurring', PaymentPayURecurringStore)

  let correctPaymentMethod = false

  const afterPlaceOrder = (payload) => {
    if (correctPaymentMethod) {
      store.commit(KEY + '/' + types.SET_TOKEN_DATA, '')
      EventBus.$emit('notification-progress-payment-start', i18n.t('Processing payment...'))

      if (timer === null) {
        timer = setInterval(() => {
          checkPaymentInformation(payload).then(response => response.json())
            .then(data => paymentResults(data, payload))
        }, 5000)
      }
    }
  }

  const checkPaymentInformation = (payload) => {
    return fetch((config.payment_payu.endpoint.payment_information).replace('{{ backendOrderId }}', payload.confirmation.backendOrderId), {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    })
  }

  let timer = null

  const paymentResults = (data, payload) => {
    if (data.result && data.result[0].redirectUri) {
      window.location.href = data.result[0].redirectUri
    } else if (data.result && data.result[0].result === 'completed') {
      EventBus.$emit('notification-progress-payment-stop')

      clearInterval(timer)
      timer = null
      paymentComplete(payload)
    } else if (data.result && data.result[0].result === 'canceled') {
      EventBus.$emit('notification-progress-payment-stop')

      clearInterval(timer)
      timer = null
      paymentCanceled(payload)
    }
  }

  const paymentComplete = (payload) => {
    store.dispatch('checkout/setThankYouPage', true)
    store.dispatch('user/getOrdersHistory', { refresh: true, useCache: true })
    EventBus.$emit('on-after-place-order') // call DL ecomm push

    store.dispatch('notification/spawnNotification', {
      type: 'success',
      message: i18n.t('Your payment has been received'),
      action1: { label: i18n.t('OK') }
    })
  }

  const paymentCanceled = (payload) => {
    store.dispatch('notification/spawnNotification', {
      type: 'error',
      message: i18n.t('Your payment has been canceled'),
      action1: { label: i18n.t('OK') }
    })
  }

  const placeOrder = () => {
    if (correctPaymentMethod) {
      let shippingDetails = store.getters['checkout/getShippingDetails'];
      store.commit(KEY + '/' + types.SET_PARCEL_LOCKER_NO, shippingDetails.pickupId)

      let additionalPaymentData = store.getters[KEY + '/' + 'getAdditionalPaymentData']

      let storeView = currentStoreView()

      additionalPaymentData['base_return_url'] = config.payment_payu.base_return_url[storeView.storeId]

      EventBus.$off('order-after-placed')
      EventBus.$bus.$on('order-after-placed', afterPlaceOrder)

      EventBus.$emit('checkout-do-placeOrder', additionalPaymentData)
    }
  }

  if (!isServer) {
    EventBus.$on('set-unique-payment-methods', methods => {
      store.commit('payment-backend-methods/' + types.SET_BACKEND_PAYMENT_METHODS, methods)
    })

    EventBus.$on('checkout-payment-method-changed', (paymentMethodCode) => {
      let methods = store.state['payment-backend-methods'].methods
      if (paymentMethodCode === 'vue_payu_gateway_recurring' && methods !== null && methods.find(item => (item.code === paymentMethodCode && item.is_server_method === true))) {
        correctPaymentMethod = true

        EventBus.$off('checkout-before-placeOrder')
        EventBus.$on('checkout-before-placeOrder', placeOrder)
        // store.commit(KEY + '/' + types.SET_PAYU_METHOD, store.state['ui'].payuMethodType)
        store.commit(KEY + '/' + types.SET_GUA_CLIENT_ID, getGuaClientId())
        store.commit(KEY + '/' + types.SET_GUA_UA, getGuaUa())
        const Component = Vue.extend(PayUWidgetComponent)
        const componentInstance = (new Component({ store: store }))
        componentInstance.$mount('#checkout-order-review-additional')
      } else {
        correctPaymentMethod = false
      }
    })
  }
}
