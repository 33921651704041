import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import config from 'config'
import { Logger } from '@vue-storefront/core/lib/logger'

export const downloadsStore = {
  namespaced: true,
  state: {
    customerDownloadableProducts: []
  },
  getters: {
    getDownloadableProducts: (state) => state.customerDownloadableProducts
  },
  mutations: {
    setCustomerDownloadableProducts  (state, payload) {
      state.customerDownloadableProducts = payload
    }
  },
  actions: {
    getUserDownloadableItems ({ commit }, { currentToken }): Promise<Response> {
      if (currentToken !== null) {
        return new Promise((resolve, reject) => {
          const url = processLocalizedURLAddress(getApiEndpointUrl(config.cart, 'downloadable_endpoint').replace('{{token}}', currentToken))
          TaskQueue.execute({
            url,
            payload: {
              method: 'GET',
              headers: {
                'Accept': 'application/json'
              },
              mode: 'cors'
            }
          }).then(resp => {
            if (resp.resultCode === 200) {
              Logger.info('Downloadable links loaded')()
              if (resp.result) {
                if (resp.result.errors) reject(resp.result.errors.message)
                return resp.result.data.customerDownloadableProducts
                  ? resolve(resp.result.data.customerDownloadableProducts.items)
                  : reject('customerDownloadableProducts is empty')
              }
            } else {
              reject(resp)
            }
          }).catch(err => {
            reject(err)
          })
        })
      }
    }
  }
}
