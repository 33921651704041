import { ActionTree } from 'vuex'
import config from 'config'
import * as types from './mutation-types'
import { processURLAddress } from '@vue-storefront/core/helpers'
import { adjustMultistoreApiUrl } from '@vue-storefront/core/lib/multistore'
import RootState from '@vue-storefront/core/types/RootState'
import SubscryptionProductState from '../types/SubscriptionProductState'
import { Logger } from '@vue-storefront/core/lib/logger'
import { TaskQueue } from '@vue-storefront/core/lib/sync'

const actions: ActionTree<SubscryptionProductState, RootState> = {
  load ({ commit, state }, { skus } = {}): Promise<Response> {
    let newSkus = skus.filter(sku => !Object.keys(state.numberOptions).includes(sku))
    if (newSkus.length) {
      return new Promise((resolve, reject) => {
        let parsedSkus = newSkus.toString()

        let url = processURLAddress(config.subscriptionProduct.endpoint) + `/${parsedSkus}`
        url = config.storeViews.multistore ? adjustMultistoreApiUrl(url) : url

        TaskQueue.execute({ url,
          payload: {
            method: 'GET',
            headers: {
              'Accept': 'application/json'
            },
            mode: 'cors'
          }
        }).then(resp => {
          if (resp.resultCode === 200) {
            Logger.info('Subscription details loaded from magento.')()
            if (resp.result && resp.result.length) {
              for (let key of Object.keys(resp.result[0])) {
                commit(types.LOAD_SUBSCRIPTION_DETAILS, { sku: key, response: resp.result[0][key].number_options })
              }
            }
            resolve(resp.result)
          } else {
            reject(resp)
          }
        }).catch(err => {
          reject(err)
        })
      })
    }
  }
}

export default actions
