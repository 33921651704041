import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { isServer } from '@vue-storefront/core/helpers'
import Vue from 'vue';
import InfoComponent from './components/Info.vue'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import * as types from './store/mutation-types'
import { getGuaClientId } from 'theme/utils/getGuaClientId'
import { getGuaUa } from 'theme/utils/getGuaUa'

const PaymentCashOnDeliveryStore = {
  namespaced: true,
  state: {
    methods: null,
    additional_payment_data: {
      gua_client_id: '',
      parcel_locker_no: ''
    }
  },
  mutations: {
    [types.SET_BACKEND_PAYMENT_METHODS] (state, paymentMethods) {
      state.methods = paymentMethods
    },
    [types.SET_GUA_CLIENT_ID] (state, gua_client_id) {
      state.additional_payment_data.gua_client_id = gua_client_id
    },
    [types.SET_GUA_UA] (state, gua_user_agent) {
      if (gua_user_agent) { state.additional_payment_data.gua_ua = gua_user_agent }
    },
    [types.SET_PARCEL_LOCKER_NO] (state, parcel_locker_no) {
      state.additional_payment_data.parcel_locker_no = parcel_locker_no
    }
  },
  getters: {
    getAdditionalPaymentData: state => state.additional_payment_data
  }
}

export const KEY = 'payment-cash-on-delivery'

export const PaymentCashOnDeliveryModule: StorefrontModule = function ({ store }) {
  store.registerModule('payment-cash-on-delivery', PaymentCashOnDeliveryStore)

  // Place the order. Payload is empty as we don't have any specific info to add for this payment method '{}'
  let correctPaymentMethod = false
  const placeOrder = () => {
    if (correctPaymentMethod) {
      let shippingDetails = store.getters['checkout/getShippingDetails'];
      store.commit(KEY + '/' + types.SET_PARCEL_LOCKER_NO, shippingDetails.pickupId)

      let additionalPaymentData = store.getters[KEY + '/' + 'getAdditionalPaymentData']

      EventBus.$emit('checkout-do-placeOrder', additionalPaymentData)
    }
  }
  // Update the methods
  let paymentMethodConfig = {
    'title': 'Cash on delivery',
    'code': 'cashondelivery',
    'cost': 0,
    'costInclTax': 0,
    'default': true,
    'offline': true,
    'is_server_method': true
  }
  store.dispatch('checkout/addPaymentMethod', paymentMethodConfig)
  if (!isServer) {
    // Update the methods
    let paymentMethodConfig = {
      'title': 'Cash on delivery',
      'code': 'cashondelivery',
      'cost': 0,
      'cost_incl_tax': 0,
      'default': true,
      'offline': true,
      'is_server_method': true
    }
    store.dispatch('checkout/addPaymentMethod', paymentMethodConfig)

    // Mount the info component when required.
    EventBus.$on('checkout-payment-method-changed', (paymentMethodCode) => {
      let methods = store.state['payment-backend-methods'].methods
      if (methods) {
        let method = methods.find(item => (item.code === paymentMethodCode))
        // if (paymentMethodCode === 'cashondelivery' && ((typeof method !== 'undefined' && !method.is_server_method) || typeof method === 'undefined') /* otherwise it could be a `payment-backend-methods` module */) {
        if (paymentMethodCode === 'cashondelivery' && ((typeof method !== 'undefined') || typeof method === 'undefined')) {
          correctPaymentMethod = true

          EventBus.$off('checkout-before-placeOrder')
          EventBus.$on('checkout-before-placeOrder', placeOrder)

          store.commit(KEY + '/' + types.SET_GUA_CLIENT_ID, getGuaClientId())
          store.commit(KEY + '/' + types.SET_GUA_UA, getGuaUa())
          store.commit(KEY + '/' + types.SET_PARCEL_LOCKER_NO, '1111111')
          // Dynamically inject a component into the order review section (optional)
          // const Component = Vue.extend(InfoComponent)
          // const componentInstance = (new Component())
          // componentInstance.$mount('#checkout-order-review-additional')
        } else {
          correctPaymentMethod = false
        }
      }
    })
  }
}
