<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
const DefaultLayout = () => import(/* webpackChunkName: "vsf-layout-default" */ './layouts/Default')

export default {
  data () {
    return {
      ordersData: []
    }
  },
  beforeCreate () {
    global.civchat = { apiKey: 'Old0hC' }
  },
  computed: {
    layout () {
      return `${(this.$route.meta.layout || 'default')}-layout`
    }
  },
  components: {
    DefaultLayout
  }
}
</script>

<style>
/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 7px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #1396EC;
  border-radius: 5px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1396EC;
}
</style>
