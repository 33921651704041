import { buildFilterProductsQuery } from '@vue-storefront/core/helpers'
import { products, entities } from 'config'
import config from 'config'

export const subscriptionProductsStore = {
  namespaced: true,
  state: {
    subscriptionProducts: [],
    simpleProducts: [],
    giftsProducts: [],
    renewableProducts: [],
    perCategory: {}
  },
  getters: {
    getSubscriptionProducts: (state) => state.subscriptionProducts,
    getSubscriptionProductsTotal: (state) => state.subscriptionProducts.length,
    getSimpleProducts: (state) => state.simpleProducts,
    getSimpleProductsTotal: (state) => state.simpleProducts.length,
    getGiftsProducts: (state) => state.giftsProducts,
    getGiftsProductsTotal: (state) => state.giftsProducts.length,
    getRenewableProducts: (state) => state.renewableProducts,
    getRenewableProductsTotal: (state) => state.renewableProducts.length,
    getOtherCategoryProducts: (state) => (id) => state.perCategory[id],
    getOtherCategoryProductsTotal: (state) => (id) => state.perCategory[id].length
  },
  mutations: {
    setSubscriptionProducts  (state, payload) {
      state.subscriptionProducts = payload
    },
    setSimpleProducts  (state, payload) {
      state.simpleProducts = payload
    },
    setGiftsProducts  (state, payload) {
      state.giftsProducts = payload
    },
    setRenewableProducts  (state, payload) {
      state.renewableProducts = payload
    },
    setOtherCategoryProducts  (state, payload) {
      state.perCategory[payload.id] = payload.value
    }
  },
  actions: {
    async loadSpecialProducts ({ commit, getters, dispatch, rootGetters }, { route = null, category = null, pageSize = 50, type = null } = {}) {
      if (!route) throw new Error('route is empty')
      const searchCategory = category || rootGetters['category-next/getCategoryFrom'](route.path) || {}
      if (type === 'simple') commit('setSimpleProducts', [])
      if (type === 'gifts') commit('setGiftsProducts', [])
      if (type === 'renewable') commit('setRenewableProducts', [])
      if (type === 'subscriptions') commit('setSubscriptionProducts', [])
      let currentCategory = null;
      if (type === 'category') {
        commit('setOtherCategoryProducts', {
          id: category.id,
          value: []
        })
        currentCategory = rootGetters['category-next/getCategoryFrom'](route.path);
      }
      if ((!config.server.categoryIdsSpecial.includes(searchCategory.id) &&
      !config.server.categoryIdsSpecial.includes(searchCategory.parent_id) &&
      ['simple', 'gifts', 'renewable'].includes(type)) ||
      (!config.server.categoryIdsSubscription.includes(searchCategory.id) &&
      !config.server.categoryIdsSubscription.includes(searchCategory.parent_id) &&
      type === 'subscriptions') || (currentCategory && currentCategory.id !== 2333 && type === 'category')) {
        return
      }
      const categoryMappedFilters = rootGetters['category-next/getFiltersMap'][searchCategory.id]
      let areSaleInQuery = route.query.hasOwnProperty('sale')
      let areSaleInQueryIsTrue = areSaleInQuery ? route.query['sale'] === 'true' : false
      let areBestselleryInQuery = route.query.hasOwnProperty('bestsellery')
      let areBestselleryInQueryIsTrue = areBestselleryInQuery ? route.query['bestsellery'] === 'true' : false
      let areNewInQuery = route.query.hasOwnProperty('new')
      let areNewInQueryIsTrue = areNewInQuery ? route.query['new'] === 'true' : false
      let areQueryInQuery = route.query.hasOwnProperty('query')
      const searchQuery = rootGetters['category-next/getCurrentFiltersFrom'](route[products.routerFiltersSource], categoryMappedFilters)
      let filterQr = buildFilterProductsQuery(searchCategory, searchQuery.filters)

      if (type === 'renewable') { // Oferty autoodnawialne
        filterQr.applyFilter({ key: 'is_recurring_payment', value: { 'eq': true } })
      } else if (type === 'simple') { // Oferty podstawowe
        filterQr.applyFilter({ key: 'type_id', value: { 'in': ['subscription', 'bundle'] } })
        filterQr.applyFilter({ key: 'is_recurring_payment', value: { 'neq': true } })
        filterQr.applyFilter({ key: 'is_gift_included', value: { 'neq': true } })
      } else if (type === 'gifts') { // Oferty z prezentami
        filterQr.applyFilter({ key: 'is_gift_included', value: { 'eq': true } })
      } else if (type === 'subscriptions') { // wszystkie prenumeraty
        filterQr.applyFilter({ key: 'type_id', value: { 'in': ['bundle', 'subscription'] } })
      }

      if (areSaleInQueryIsTrue) {
        const rangeqr = {}
        rangeqr['gt'] = 0
        filterQr.applyFilter({ key: '', value: { 'activeSpecial': '' } })
      }
      if (areBestselleryInQueryIsTrue) {
        filterQr.applyFilter({ key: 'bestseller', value: { 'eq': 1 } })
      }
      if (areNewInQueryIsTrue) {
        const rangeqr = {}
        rangeqr['gte'] = 'now/d'
        filterQr.applyFilter({ key: 'news_to_date', value: rangeqr })
      }
      if (areQueryInQuery) {
        filterQr.setSearchText(route.query['query'])
      }
      let selectedSort
      if (searchQuery.sort) {
        selectedSort = searchQuery.sort
      } else if (category.default_sort_by) {
        selectedSort = config.server.attrSortByAsc.includes(category.default_sort_by)
          ? category.default_sort_by
          : category.default_sort_by + ':desc'
      } else {
        selectedSort = `${products.defaultSortBy.attribute}:${products.defaultSortBy.order}`
      }
      const { items } = await dispatch('product/findProducts', {
        query: filterQr,
        sort: selectedSort,
        includeFields: entities.productList.includeFields,
        excludeFields: entities.productList.excludeFields,
        size: pageSize,
        configuration: searchQuery.filters,
        options: {
          populateRequestCacheTags: true,
          prefetchGroupProducts: false,
          setProductErrors: false,
          fallbackToDefaultWhenNoAvailable: true,
          assignProductConfiguration: false,
          separateSelectedVariant: false
        }
      }, { root: true })

      if (type === 'simple') {
        commit('setSimpleProducts', items)
      } else if (type === 'gifts') {
        commit('setGiftsProducts', items)
      } else if (type === 'renewable') {
        commit('setRenewableProducts', items)
      } if (type === 'subscriptions') {
        commit('setSubscriptionProducts', items)
      } if (type === 'category') {
        commit('setOtherCategoryProducts', {
          id: category.id,
          value: items
        })
      }

      return items
    }
  }
}
