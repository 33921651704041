import config from 'config'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { isSpecialPriceActive } from '@vue-storefront/core/modules/catalog/helpers/taxCalc'

export function getPathForStaticPage (path: string) {
  const { storeCode } = currentStoreView()
  const isStoreCodeEquals = storeCode === config.defaultStoreCode

  return isStoreCodeEquals ? `/i${path}` : path
}

export async function fetchOmnibusPriceList (productsSkus: string[], currencyCode: string) {
  try {
    return await fetch(config.omnibus.url + '/api/category_prices?key=' + config.omnibus.key,
      {
        method: 'POST',
        body: JSON.stringify({
          ids: productsSkus
        })
      })
      .then(response => response.json())
      .then(data => {
        const omnibusPriceMap = {}
        for (let productSku of Object.keys(data)) {
          const productPrice = data[productSku] && data[productSku][currencyCode]
          if (productPrice) {
            omnibusPriceMap[productSku] = parseFloat(productPrice)
          }
        }
        return omnibusPriceMap
      })
  } catch (err) {
    throw new Error('Problem with fetching prices from omnibus API')
  }
}

function isProductOnSale (product) {
  if (product.type_id === 'bundle') {
    return product.original_special_price &&
      isSpecialPriceActive(product.special_from_date, product.special_to_date)
  }
  return product.price_incl_tax &&
    (product.special_price || product.special_price_incl_tax)
}

function isProductConfigurable (product) {
  return product.configurable_children && product.configurable_children.length
}

function getMinPriceChild (product) {
  return product.configurable_children
    .reduce((prev, curr) => {
      if (!prev) return curr
      return prev.price_incl_tax < curr.price_incl_tax
        ? prev
        : curr
    }, null)
}

export function proccessPrices (omnibusPriceMap) {
  let processedOmnibusPriceMap = {}
  for (let sku of Object.keys(omnibusPriceMap)) {
    const fixedValue = Number(omnibusPriceMap[sku]).toFixed(2)
    processedOmnibusPriceMap[sku] = Number(fixedValue)
  }
  return processedOmnibusPriceMap
}

export function getProductsOnSaleSkus (products) {
  return products
    .map(product => {
      return isProductConfigurable(product)
        ? getMinPriceChild(product)
        : product
    })
    .filter(isProductOnSale)
    .map(product => product.sku)
}
