import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { stat } from 'fs'
import state from 'src/modules/vsf-magento-wishlist/store/state'

export const uiStore = {
  namespaced: true,
  state: {
    sidebar: false,
    microcart: false,
    wishlist: false,
    searchpanel: false,
    newsletterPopup: false,
    overlay: false,
    loader: false,
    paymentLoader: false,
    authElem: 'login',
    isAuthElem: false,
    checkoutMode: false,
    openMyAccount: false,
    submenu: {
      depth: false,
      path: []
    },
    checkoutTypes: ['Osoba prywatna', 'Firma'],
    selectedCheckoutType: 'Osoba prywatna',
    isNipDataResponse: '',
    isSaleFilterActive: false,
    isNewFilterActive: false,
    isQueryFilterActive: false,
    isBestselleryFilterActive: false,
    outboundLink: {},
    selectedBanner: {},
    viewedBanner: {},
    selectedBillingId: null,
    selectedShippingId: null,
    ngCheckbox: false,
    payuMethodType: {},
    mobileFilter: false,
    usercomCheckout: {},
    queryText: ''
  },
  getters: {
    getNgCheckbox: (state) => state.ngCheckbox
  },
  mutations: {
    routeChanged () {
    },
    setQueryText (state, action) {
      state.queryText = action
    },
    setPayuMethod (state, payload) {
      state.payuMethodType = payload
    },
    toogleNgCheckbox (state, value) {
      state.ngCheckbox = value
    },
    toogleMobileFilter (state, value) {
      state.mobileFilter = value
    },
    clearAuthModals (state) {
      state.isAuthElem = false
    },
    setBillingId (state, payload) {
      state.selectedBillingId = payload
    },
    setShippingId (state, payload) {
      state.selectedShippingId = payload
    },
    usercomCheckoutPush (state, payload) {
      state.usercomCheckout = payload
    },
    openOutboundLink (state, payload) {
      state.outboundLink = payload
    },
    setQueryFilter (state, action) {
      state.isQueryFilterActive = action === true
    },
    setNewFilter (state, action) {
      state.isNewFilterActive = action === true
    },
    setBestselleryFilter (state, action) {
      state.isBestselleryFilterActive = action === true
    },
    setSaleFilter (state, action) {
      state.isSaleFilterActive = action === true
    },
    setNipDataResponse (state, action) {
      state.isNipDataResponse = action
    },
    setCheckoutType (state, action) {
      state.selectedCheckoutType = action
    },
    clearAllModals (state) {
      state.searchpanel = false
      state.wishlist = false
      state.sidebar = false
      state.microcart = false
      state.isAuthElem = false
    },
    setCheckoutMode (state, action) {
      state.checkoutMode = action === true
    },
    // for GTM banner track
    VIEW_BANNER (state, payload) {
      state.viewedBanner = payload
    },
    CLICK_BANNER (state, payload) {
      state.selectedBanner = payload
    },
    setMicrocart (state, action) {
      uiStore.mutations.clearAllModals(state)
      state.microcart = action === true
      state.overlay = action === true
    },
    setSidebar (state, action) {
      uiStore.mutations.clearAllModals(state)
      state.sidebar = action === true
      state.overlay = action === true
    },
    setSubmenu (state, { id, depth }) {
      if (id) {
        state.submenu.path.push(id)
      } else if (state.submenu.path.length) {
        setTimeout(() => {
          state.submenu.path.pop()
        }, 300)
      }
      state.submenu.depth = state.submenu.depth > 0 && depth
    },
    setSearchpanel (state, action) {
      uiStore.mutations.clearAllModals(state)
      state.searchpanel = action === true
      state.overlay = action === true
    },
    setWishlist (state, action) {
      uiStore.mutations.clearAllModals(state)
      state.wishlist = action === true
      state.overlay = action === true
    },
    setOverlay (state, action) {
      state.overlay = action === true
    },
    setLoader (state, action) {
      state.loader = action === true
    },
    setPaymentLoader (state, action) {
      state.paymentLoader = action === true
    },
    setAuthElem (state, action) {
      if (state.isAuthElem === true) {
        state.isAuthElem = false
      } else {
        state.isAuthElem = true
      }
      state.searchpanel = false
      state.wishlist = false
      state.sidebar = false
      state.microcart = false
      state.authElem = action
    }
  },
  actions: {
    setPayuMethod ({ commit }, payload) {
      commit('setPayuMethod', payload)
    },
    toogleNgCheckbox ({ commit }, payload) {
      commit('toogleNgCheckbox', payload)
    },
    toogleMobileFilter ({ commit }, payload) {
      commit('toogleMobileFilter', payload)
    },
    toggleQueryFilter ({ commit, state }, payload) {
      commit('setQueryFilter', !state.isQueryFilterActive)
      commit('setQueryText', payload)
    },
    resetQueryFilter ({ commit, state }) {
      commit('setQueryFilter', false)
      commit('setQueryText', '')
    },
    toggleSaleFilter ({ commit, state }) {
      commit('setSaleFilter', !state.isSaleFilterActive)
    },
    resetSaleFilter ({ commit, state }) {
      commit('setSaleFilter', false)
    },
    toggleNewFilter ({ commit, state }) {
      commit('setNewFilter', !state.isNewFilterActive)
    },
    toggleBestselleryFilter ({ commit, state }) {
      commit('setBestselleryFilter', !state.isBestselleryFilterActive)
    },
    resetNewFilter ({ commit, state }) {
      commit('setNewFilter', false)
    },
    changeNipDataResponse ({ commit }, data) {
      commit('setNipDataResponse', data)
    },
    changeCheckoutType ({ commit }, data) {
      commit('setCheckoutType', data)
    },
    toggleMicrocart ({ commit, state }) {
      commit('setMicrocart', !state.microcart)
    },
    toggleWishlist ({ commit, state }) {
      commit('setWishlist', !state.wishlist)
    },
    closeMicrocart ({ commit, state }) {
      if (state.microcart) commit('setMicrocart', false)
    },
    closeWishlist ({ commit, state }) {
      if (state.wishlist) commit('setWishlist', false)
    }
  }
}
