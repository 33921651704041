<template>
  <div class="mb15 mt20 payment-cash-on-delivery-info-container">
    <div class="py10 px20-xs">
      <div class="row">
        <div class="col-xs-12 col-sm-6 flex middle-xs blik-text pt10">
          <p class="h3 m0" @click="changeCode">
            kod BLIK
          </p>
          <p class="cl-secondary-orange mt8 h6 mb8 pointer" @click="$bus.$emit('modal-show', 'modal-blik')">
            Skąd wziąć kod?
          </p>
          <!-- <p @click="$bus.$emit('blik-notification-progress-start', 'Teraz potwierdź płatność PINem za pomocą aplikacji w swoim telefonie')">loader</p> -->
          <!-- <p class="cl-secondary-orange mt8 h6 mb8" v-if="!$v.authorization_code.required">
            Pole jest wymagane
          </p>
          <p class="cl-secondary-orange mt8 h6 mb8" v-if="!$v.authorization_code.minLength && $v.authorization_code.required">
            Pole musi zawierać co najmniej 6 znaków
          </p>
          <p class="cl-secondary-orange mt8 h6 mb8" v-if="!$v.authorization_code.maxLength && $v.authorization_code.minLength && $v.authorization_code.required">
            Pole musi zawierać maksymalnie 6 znaków
          </p> -->
        </div>
        <base-input
          class="col-xs-12 col-sm-4"
          type="text"
          name="authorizationCode"
          :blik="true"
          v-model="authorization_code"
          @blur="$v.authorization_code.$touch(), changeCode"
          :validations="[
            {
              condition: !$v.authorization_code.required,
              text: 'Pole jest wymagane'
            },
            {
              condition: !$v.authorization_code.minLength && $v.authorization_code.required,
              text: 'Pole musi zawierać co najmniej 6 znaków'
            },
            {
              condition: !$v.authorization_code.maxLength && $v.authorization_code.minLength && $v.authorization_code.required,
              text: 'Pole musi zawierać maksymalnie 6 znaków'
            }
          ]"
        />
      </div>
      <modal :name="'modal-blik'" :width="600">
        <p slot="header" class="align-center blik__header">
          Pobierz kod BLIK z aplikacji mobilnej swojego banku
        </p>
        <div slot="content" class="table-box">
          <div class="row">
            <div class="col-xs-12 center-xs">
              <img src="/assets/blik-phone.png" alt="blik phone">
            </div>
          </div>
          <div class="row banks mx40-sm mt40">
            <div class="col-xs-2 col-bank">
              <a class="banks__link" target="_blank" href="https://www.aliorbank.pl/dodatkowe-informacje/bankowosc-elektroniczna/nowa-bankowosc-mobilna.html">
                <img src="/assets/banks/Alior-1.png" alt="Alior Bank">
              </a>
            </div>
            <div class="col-xs-2 col-bank">
              <a class="banks__link" target="_blank" href="https://www.bankmillennium.pl/bankowosc-elektroniczna/bankowosc-mobilna/aplikacja-mobilna-klienci-indywidualni-biznes">
                <img src="/assets/banks/Millenium.png" alt="Bank Millennium">
              </a>
            </div>
            <div class="col-xs-2 col-bank">
              <a class="banks__link" target="_blank" href="https://santander.pl/ms/plac-mobilnie">
                <img src="/assets/banks/santander.png" alt="Santander Bank Polska">
              </a>
            </div>
            <div class="col-xs-2 col-bank">
              <a class="banks__link" target="_blank"
                 href="http://www.ingbank.pl/indywidualni/bankowosc-elektroniczna/aplikacje-mobilne"
              >
                <img src="/assets/banks/ING-1.png" alt="ING Bank Śląski">
              </a>
            </div>
            <div class="col-xs-2 col-bank">
              <a class="banks__link" target="_blank" href="https://www.mbank.pl/indywidualny/uslugi/uslugi/blik/">
                <img src="/assets/banks/mBank.png" alt="mBank">
              </a>
            </div>
            <div class="col-xs-2 col-bank">
              <a class="banks__link" target="_blank" href="http://www.pkobp.pl/aplikacja-iko/pobierz/">
                <img src="/assets/banks/PKO.png" alt="PKO Bank Polski">
              </a>
            </div>
            <div class="col-xs-2 col-bank">
              <a class="banks__link" target="_blank"
                 href="https://www.getinbank.pl/klienci-indywidualni/oferta/bankowosc-internetowa-i-mobilna/bankowosc-mobilna"
              >
                <img src="/assets/banks/Getin.png" alt="Getin Bank">
              </a>
            </div>
            <div class="col-xs-2 col-bank">
              <a class="banks__link" target="_blank" href="https://www.t-mobilebankowe.pl/bankowosc-mobilna">
                <img src="/assets/banks/T-Mobile.png" alt="T-Mobile Usługi Bankowe">
              </a>
            </div>
            <div class="col-xs-2 col-bank">
              <a class="banks__link" target="_blank" href="https://www.bgzbnpparibas.pl/aplikacja-mobilna-go-mobile">
                <img src="/assets/banks/logo-bgz.png" alt="BNP Paribas">
              </a>
            </div>
            <div class="col-xs-2 col-bank">
              <a class="banks__link" target="_blank"
                 href="https://www.credit-agricole.pl/klienci-indywidualni/bankowosc-elektroniczna/serwis-mobilny-ca24"
              >
                <img src="/assets/banks/Ikona-aplikacji-CABP-round.png" alt="Credit Agricole">
              </a>
            </div>
            <div class="col-xs-2 col-bank">
              <a class="banks__link" target="_blank"
                 href="https://www.pekao.com.pl/klient-indywidualny/bankowosc-elektroniczna/blik.html"
              >
                <img src="/assets/banks/Pekao_KI_144x144.png" alt="Bank Pekao S.A.">
              </a>
            </div>
            <div class="col-xs-2 col-bank">
              <a class="banks__link" target="_blank" href="https://www.sgb.pl/portfel-sgb/">
                <img src="/assets/banks/Portfel-SGB.png" alt="Spółdzielcza Grupa Bankowa">
              </a>
            </div>
          </div>
          <div>
            <div class="row mt40 center-xs">
              <div class="col-xs-4">
                <p @click="$bus.$emit('modal-hide', 'modal-blik')" class="blik__close pointer">
                  Rozumiem
                </p>
              </div>
            </div>
          </div>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import BaseInput from 'theme/components/core/blocks/Form/BaseInput'
import { KEY } from './../index'
import * as types from './../store/mutation-types'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import Modal from 'theme/components/core/Modal.vue'

export default {
  components: {
    BaseInput,
    Modal
  },
  mixins: [validationMixin],
  name: 'PaymentPayUBlik',
  validations: {
    authorization_code: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(6)
    }
  },
  computed: {
    isValid () {
      return !this.$v.authorization_code.$invalid
    },
    changeCode () {
      return this.$emit('changeUpdate', this.isValid)
    },
    authorization_code: {
      get () {
        return this.$store.getters[KEY + '/' + 'getAuthorizationCode']
      },
      set (newValue) {
        this.$store.commit(KEY + '/' + types.SET_AUTHORIZATION_CODE, newValue)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .px20-xs {
    @media (max-width: 767px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .blik-text {
    flex-direction: column;
  }
  .banks__link img {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    height: auto;
    display: block;
    margin-bottom: 10px;
  }
  .blik__header {
    font-size: 30px;
    text-transform: none;
  }
  .blik__close {
    color: white;
    background-color: #da3800;
    border-radius: 20px;
    margin: 0;
    padding: 10px 0;
    font-size: 18px;
  }
  .mx40-sm {
    @media (min-width: 768px) {
      margin-left: 40px;
      margin-right: 40px;
    }
  }
</style>
