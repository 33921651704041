import { CategoryResolver } from 'theme/helpers/category_resolver/CategoryResolver'

export function categoryResolver (category, categoryMain, store) {
  const menuCategories = store.getters['category-next/getMenuCategories']
  let resolver = CategoryResolver.get()
  resolver.setCategories(menuCategories)
  let categoriesIdsFromResolver = []
  let deepestCategory = []
  let mainCategory = ''
  if (!category) {
    return { deepestCategory, mainCategory, categoriesIdsFromResolver }
  }
  categoriesIdsFromResolver = resolver.getDeepestCategories(category, false)
  deepestCategory = categoriesIdsFromResolver.map((x) => resolver.getNamedPath(x))
  mainCategory = categoryMain ? resolver.idToPathName(categoryMain) : ''
  return { deepestCategory, mainCategory, categoriesIdsFromResolver }
}
